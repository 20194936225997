<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-12 01:10:46
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-17 10:50:49
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\App\Storage\Storage\PrintStorage.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box" style="height: 75vh">
      <div class="print-preview-box">
        <div id="printMe">
          <div
            class="print-preview-content"
            v-for="item in printData"
            :key="item.kst_tag_id"
          >
            <!-- 标题 -->
            <div class="ppc-title">
              <p>库位标签</p>
            </div>
            <!-- 条码 -->
            <div class="ppc-barcode">
              <svg
                class="barcode"
                jsbarcode-format="CODE128"
                :jsbarcode-value="item.kst_tag_id"
                jsbarcode-textmargin="0"
                jsbarcode-height="50"
                jsbarcode-fontoptions="bold"
              ></svg>
            </div>
            <!-- 备注 -->
            <div class="ppc-notes">
              <div class="ppc-notes-title">
                <p>备注：{{ item.notes }}</p>
              </div>
              <div class="ppc-notes-content"></div>
            </div>
          </div>
        </div>
      </div>

      <el-divider></el-divider>

      <el-button
        size="small"
        type="primary"
        icon="el-icon-check"
        v-print="'#printMe'"
      >
        打印
      </el-button>
      <el-button size="small" icon="el-icon-close" @click="isShow = false">
        取消
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import JsBarcode from "jsbarcode";
import print from "vue-print-nb";
export default {
  directives: { print },
  data() {
    return {
      title: "打印预览", //弹窗标题
      isShow: false, //控制弹窗是否显示
      printData: [], //打印数据
    };
  },
  watch: {
    isShow() {
      if (this.isShow) {
        setTimeout(() => {
          JsBarcode(".barcode").init();
        }, 3000);
      }
    },
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.$nextTick(() => {
        this.loading = false;
      });
      this.setPrintData();
    },

    setPrintData() {
      this.printData = [
        { kst_tag_id: "CNFY-NXERXA", notes: "" },
        { kst_tag_id: "CNFY-9S5AZW", notes: "" },
        { kst_tag_id: "CNFY-8LIEFE", notes: "" },
        { kst_tag_id: "CNFY-91STF1", notes: "" },
        { kst_tag_id: "CNFY-GM78TQ", notes: "" },
        { kst_tag_id: "CNFY-H1YDO8", notes: "" },
      ];
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>

<style scoped>
.vue-box {
  background-color: rgb(243, 243, 243);
}

.print-preview-box {
  width: 30%;
  margin: auto;
  text-align: center;
}

.print-preview-content {
  width: 100%;
  height: auto;
  margin: auto;
  margin-bottom: 18px;
  padding: 0;
  border-radius: 10px;
  display: block;
  text-align: center;
  overflow: hidden;
  page-break-after: always;
  background-color: rgb(255, 255, 255);
}

.ppc-title {
  width: 100%;
  margin: auto;
  padding-top: 2mm;
  text-align: center;
}

.ppc-title p {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

.ppc-barcode {
  width: 100%;
  margin: auto;
}

.ppc-barcode svg {
  width: 95%;
  height: 65px;
}

.ppc-notes {
  width: 100%;
  margin: auto;
}

.ppc-notes p {
  text-align: left;
  padding-left: 15px;
}

.ppc-notes-content {
  width: 100%;
  height: 20px;
}

/*去除页眉页脚*/
@page {
  size: auto; /* auto is the initial value */
  margin: 0mm; /* this affects the margin in the printer settings */
}

html {
  background-color: #ffffff;
  margin: 0; /* this affects the margin on the html before sending to printer */
}

body {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", Tahoma, Arial,
    sans-serif;
  margin: 1mm 3mm 1mm 3mm; /* margin you want for the content */
}
</style>
